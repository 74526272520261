import { tv } from '@/utils/styles';

interface CountryFlagProps {
  countryCode?: string;
  className?: string;
}

const CountryFlag = ({ countryCode = '', className }: CountryFlagProps) => {
  const { base } = styles();

  const path = countryCode ? `/assets/flags/${countryCode.toLowerCase()}.svg` : `/assets/flags/xx.svg`;

  return (
    <span className={base({ className })}>
      <img src={path} width="27px" height="20px" alt={`Country flag: ${countryCode}`} loading="lazy" />
    </span>
  );
};

const styles = tv({
  slots: {
    base: 'inline-block h-5 shrink-0 overflow-hidden [&_img]:block [&_img]:h-full [&_img]:w-auto',
  },
  variants: {
    size: {
      '3': {
        base: 'h-3',
      },
      '4': {
        base: 'h-4',
      },
      '5': {},
    },
  },
});

export { CountryFlag };
