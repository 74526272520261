import { get, isFunction } from 'lodash';
import { forwardRef } from 'react';

import { Error } from '@/components/inputs/error';
import { Label } from '@/components/inputs/label';
import { OptionItem } from '@/types';
import { Field, FieldErrors } from '@/types/form-types';
import { tv } from '@/utils/styles';

export type RadioTabsProps = Field &
  FieldErrors & {
    name: string;
    options: OptionItem[];
    selected: string;
    disabled?: boolean;
    variant?: 'light';
    size?: 'small' | 'medium';
    label?: string;
    onChange?: (val: string) => void;
  };

const RadioTabs = forwardRef<HTMLDivElement, RadioTabsProps>(
  ({ name, options, selected, label, disabled, onChange, variant = 'light', size = 'medium', errors }, ref) => {
    const fieldError = get(errors, name);

    const handleOnClick = (selection: string): void => {
      if (isFunction(onChange)) {
        onChange(selection);
      }
    };

    const { base, inner, tab, button, divider, input, error } = radioTabsStyles({
      variant,
      size,
      fieldError: !!fieldError,
    });

    return (
      <div ref={ref} className={base()}>
        {label && <Label htmlFor={`radio-group-${name}`}>{label}</Label>}
        <div role="radiogroup" aria-labelledby={`radio-group-${name}`} className={inner()}>
          {options.map((option) => {
            const id = `radio-tab-${name}-${option.value}`;
            const isChecked = selected === option.value;
            return (
              <label key={option.value} htmlFor={id} className={tab()}>
                <input
                  type="radio"
                  id={id}
                  name={name}
                  checked={isChecked}
                  disabled={disabled}
                  onChange={() => handleOnClick(option.value)}
                  className={input()}
                />
                <span className={button()}>{option.label}</span>
                <span className={divider()} />
              </label>
            );
          })}
        </div>
        {fieldError?.message && <Error className={error()}>{fieldError.message}</Error>}
      </div>
    );
  },
);

const radioTabsStyles = tv({
  slots: {
    base: '',
    inner: 'inline-grid grid-flow-col gap-px rounded-md p-0.5 shadow-softer',
    tab: 'group relative flex whitespace-nowrap',
    divider: 'absolute -left-px bottom-[3px] top-[3px] w-px group-first:hidden',
    button:
      'radio-tab-button flex cursor-pointer items-center rounded bg-white px-3 text-grey-700 transition-colors duration-150 peer-checked:cursor-default peer-disabled:pointer-events-none peer-disabled:cursor-default peer-disabled:opacity-60',
    input: 'peer absolute -left-[9999px]',
    error: 'mb-2 text-forms-states-error',
  },
  variants: {
    variant: {
      light: {
        button:
          'peer-checked:!bg-lightBlue-500 peer-checked:!text-white hover:[&:not(:disabled)]:bg-lightBlue-100 hover:[&:not(:disabled)]:text-grey-700',
        inner: 'border border-grey-300 bg-white',
        divider: 'bg-grey-200',
      },
    },
    size: {
      small: {
        inner: 'h-[var(--input-height-small)]',
        button: 'text-sm',
      },
      medium: {
        inner: 'h-[var(--input-height)]',
        button: 'text-baseSm',
      },
    },
    fieldError: {
      true: {
        inner: 'border-forms-states-error',
        button: 'text-forms-states-error',
      },
    },
  },
});

RadioTabs.displayName = 'RadioTabs';

export { RadioTabs };
